import { CompanyApi } from "@/api";
import { Companies } from "@/api/companies";
import Icon from "@/components/base/Icon";
import { StatusChipRender } from "@/components/widget/status-chip";
import { Column } from "@/components/widget/table/partials/custom-table";
import useClipboard from "@/hooks/useClipboard";
import useProfileInfo from "@/hooks/useProfileInfo";
import useAccountsStore from "@/store/accounts";
import UiStore from "@/store/ui";
import UserSessionStore from "@/store/user-session";
import { checkDisplayOfFullDate, keyBy } from "@/utils";
import useCheckDevice from "@/utils/useCheckDevice";
import { Button } from "@dilypse/mui/base";
import { IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";

import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkRouter } from "react-router-dom";

type ColumnProps = Column & {
  id:
  | "location_name"
  | "feedback_url"
  | "company_name"
  | "location_status"
  | "plan"
  | "vendor"
  | "created"
  | "action"
  | string;
};

const useTableComptes = () => {
  const { t } = useTranslation();
  const { preferredLanguage } = UiStore();
  const rowsPerPage = 100;
  const [totalCount, setTotalCount] = useState(0);
  const [activeSort, setActiveSort] = useState<{
    sort_by: string;
    sort_order: "asc" | "desc";
  }>({
    sort_by: "created",
    sort_order: "desc",
  });
  const [rows, setRows] = useState<Companies[]>([]);
  const { profile } = UserSessionStore();
  const { isSuperAdmin, isRoleSuperAdmin } = useProfileInfo();
  const { isSafari } = useCheckDevice();

  const {
    filter: { search, status },
    update: updateAccounts,
    currentPage,
  } = useAccountsStore();

  const { clipboardValue, handleClipboardCopy } = useClipboard();

  const fetchCompanies = useCallback(
    (filter = {}) => {
      if (!profile?.uid) {
        return Promise.resolve({ data: [], metadata: { _count: 0 } });
      }
      const query = {
        page_number: 1,
        page_size: rowsPerPage,
        user_uid: profile?.uid,
        ...activeSort,
        ...filter,
      };
      return CompanyApi.list(query);
    },
    [profile?.uid, rowsPerPage, activeSort]
  );

  const refreshRows = useCallback(
    async (filterParams) => {
      const response: any = await fetchCompanies(filterParams);
      const populated =
        response?.data?.map((data) => ({
          ...data,
          created:
            data.created &&
            dayjs(data.created, "YYYY-MM-DD").format("DD MMMM YYYY"),
        })) || [];

      setRows(populated);
      setTotalCount(response?.metadata?._count);
    },
    [fetchCompanies]
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      updateAccounts({ currentPage: newPage, filter: { status, search } });
    },
    [refreshRows, status, search, updateAccounts]
  );

  const handleSort = useCallback(
    (columnId: string, sorted: boolean) => {
      setActiveSort((prev) => ({
        ...prev,
        sort_by: columnId,
        sort_order: sorted ? "asc" : "desc",
      }));
    },
    [setActiveSort]
  );

  const columns: readonly ColumnProps[] = useMemo(() => {
    const baseColumns = [
      {
        id: "location_name",
        label: t("COMMON.BUSINESS"),
        width: !isRoleSuperAdmin ? "25%" : "15%",
      },
      {
        id: "company_name",
        label: t("COMMON.COMPANY"),
        width: "10%",
        sortable: true,
        component: ({ column, row }) => {
          return (
            <Link
              component={LinkRouter}
              to={`/accounts/edit/${row.company_uid}`}
            >
              {row[column.id]}
            </Link>
          );
        },
      },
      {
        id: "location_status",
        label: t("COMMON.STATUT"),
        width: "17%",
        component: ({ column, row }) => {
          return StatusChipRender(row[column.id]);
        },
      },
      {
        id: "plan",
        label: t("COMMON.LICENSE"),
        width: "9%",
      },
      {
        id: "vendor",
        label: t("COMMON.RESELLER"),
        width: "9%",
        hidden: !isSuperAdmin,
      },
      {
        id: "created",
        label: t("COMMON.CREATED_DATE"),
        width: "11%",
        sortable: true,
        component: ({ column, row }) => {
          return (
            row[column.id] &&
            checkDisplayOfFullDate(row[column.id], preferredLanguage)
          );
        },
      },
      {
        id: "action",
        label: "",
        width: isSafari ? "19%" : "14%",
        component: ({ row }) => {
          return (
            <Button
              onClick={() =>
                window.open(
                  `/redirect?company_uid=${row.company_uid}&front_base_url=${row.front_base_url}`,
                  "_blank"
                )
              }
            >
              {t("COMMON.CONNEXION")}
            </Button>
          );
        },
      },
    ];

    if (isRoleSuperAdmin) {
      baseColumns.splice(1, 0, {
        id: "feedback_url",
        label: t("COMMON.FEEDBACK_URL"),
        width: "10%",
        component: ({ row }) => {
          if (row.links?.length === 0 || row.links === undefined) {
            return (
              <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  width={"60px"}
                ></Typography>
                <Tooltip title={t("COMMON.COPY_MISSING_FEEDBACK_URL_TOOLTIP")}>
                  <IconButton>
                    <Icon name="info" />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          }
          const url = row.links.find((link) => link.type === "NFC");

          if (clipboardValue && url.value === JSON.parse(clipboardValue)) {
            return (
              <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  width={"60px"}
                >
                  {t("COMMON.COPIED")}
                </Typography>
                <IconButton color="success">
                  <Icon name="check" />
                </IconButton>
              </Stack>
            );
          }
          return (
            <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
              <Typography variant="body2" color="text.secondary" width={"60px"}>
                {t("COMMON.COPY")}
              </Typography>
              <IconButton
                onClick={() => handleClipboardCopy(JSON.stringify(url.value))}
              >
                <Icon name="copy" />
              </IconButton>
            </Stack>
          );
        },
      });
    }
    return baseColumns;
  }, [
    t,
    preferredLanguage,
    isSuperAdmin,
    clipboardValue,
    isRoleSuperAdmin,
    isSafari,
  ]);

  const getBusinessStatus = useCallback(async (user_uid: string) => {
    const { data } = await CompanyApi.getBusinessesStatus({ user_uid });
    updateAccounts({
      count_by_status: keyBy(data, "status"),
    });
  }, []);

  useEffect(() => {
    if (profile?.uid) {
      getBusinessStatus(profile?.uid);
    }
  }, [profile?.uid]);

  useEffect(() => {
    refreshRows({ status, search, page_number: currentPage + 1 });
  }, [refreshRows, currentPage, status, search]);

  return {
    columns,
    page: currentPage,
    rowsPerPage,
    rows,
    totalCount,
    activeSort,
    handleChangePage,
    handleSort,
  };
};

export default useTableComptes;
