import { CompanyApi } from "@/api";
import { NetworkType } from "@/api/companies";
import StyledAutocomplete from "@/components/base/styled-autocomplete";
import MAX_INPUT from "@/data/limiter";
import { useDebounce } from "@/hooks/useDebounce";
import CompanyStore from "@/store/company";
import UserSessionStore from "@/store/user-session";
import { Input } from "@dilypse/mui/base/input";

import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
interface NetworkAutocompleteProps {
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  hasValue?: boolean;
  helperText?: string;
  inputValueChange?: (value: string) => void;
}

interface NetworkNetworkOptions {
  label: string;
  value: string;
  otherInfos?: NetworkType;
}

const NetworkAutocomplete = (props: NetworkAutocompleteProps) => {
  const {
    label,
    defaultValue,
    disabled = false,
    required,
    error,
    hasValue = false,
    helperText = "",
    inputValueChange,
  } = props;
  const [options, setOptions] = useState<NetworkNetworkOptions[]>([]);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const { profile } = UserSessionStore();
  const searchValue = useDebounce(inputValue, 500);
  const [inputExists, setInputExists] = useState<boolean>(false);
  const { updateState } = CompanyStore();
  const [hasSelectedValue, setHasSelectedValue] = useState(hasValue);
  const { company_uid } = useParams();

  const initState = useCallback(() => {
    setHasSelectedValue(true);
    setOptions([]);
    setOpen(false);
  }, []);

  const getNetwork = useCallback(async () => {
    const resp = await CompanyApi.getNetwork({
      user_uid: profile?.uid,
      keyword: inputValue,
    });
    let newOptions = [];

    if (resp) {
      newOptions = resp.map((item: NetworkType) => {
        return {
          label: item?.network_name,
          value: item?.network_name,
          otherInfos: item,
        };
      });
      setOpen(newOptions?.length > 0);
    }
    setOptions(newOptions);
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "" || searchValue?.length < 3 || hasSelectedValue) {
      initState();
      return undefined;
    }
    getNetwork();
  }, [searchValue, profile?.uid]);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <StyledAutocomplete
      inputValue={inputValue || ""}
      freeSolo
      filterSelectedOptions
      options={options}
      multiple={false}
      open={open}
      disabled={!!company_uid || disabled}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      renderInput={(params) => (
        <Input
          error={error}
          required={required}
          {...params}
          label={label}
          helperText={helperText}
        />
      )}
      onBlur={() => {
        const exists = options.filter((item) => item?.label === inputValue);
        if (!hasSelectedValue && exists.length) {
          setInputExists(true);
          initState();
          const fields = exists[0]?.otherInfos;
          delete fields["network_name"];
          updateState({
            disabledFields: fields,
          });
        }
      }}
      onChange={(_: any, newValue: NetworkNetworkOptions) => {
        initState();
        const fields = newValue?.otherInfos;
        delete fields["network_name"];
        updateState({
          disabledFields: fields,
        });
        setInputExists(false);
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue === "") {
          updateState({
            disabledFields: null,
          });
        }
        if (newInputValue.length <= MAX_INPUT.TEXT) {
          setInputValue(newInputValue);
          setHasSelectedValue(false);
          setInputExists(false);

          if (typeof inputValueChange === "function") {
            inputValueChange(newInputValue);
          }
        }
      }}
    />
  );
};

export default NetworkAutocomplete;
