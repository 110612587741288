import { Box, Stack, Typography } from "@mui/material"
import Icon from "../../base/Icon"
import IconNames from "@/types/icons"

type HeaderTitleProps = {
    title: string
    icon: IconNames
}

const HeaderTitle = (props: HeaderTitleProps) => {
    return <Box data-testid="header-title">
        <Stack flexDirection={"row"} gap={"8px"} alignItems={"center"}>
            <Box sx={{
                backgroundColor: "#0180FF52",
                width: 48,
                height: 48,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "16px"
            }} data-testid="icon-box">
                <Icon sx={{ width: 32, height: 32, color: "#0180FF" }} name={props.icon} />
            </Box>
            <Typography variant="h1" data-testid="title">{props.title}</Typography>
        </Stack>
    </Box>
}

export default HeaderTitle