import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon";

type DialogConfirmationProps = {
  loading: boolean;
  open: boolean;
  textContent: string;
  title: string;
  buttonText: string;
  displayIcon?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  t: (key: string) => string;
};

const DialogConfirmation = ({
  loading,
  open,
  textContent,
  title,
  buttonText,
  displayIcon = true,
  onSubmit,
  onCancel,
  t,
}: DialogConfirmationProps) => {

  return (
    <Dialog
      open={open}
      onClose={() => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <Tooltip title={t("BUTTON.CLOSE")}>
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            sx={{
              position: "absolute",
              p: 0,
              right: 0,
              top: 4,
              color: (theme: any) => theme.palette.primary.main,
            }}
            disabled={loading}
          >
            <Icon name="close" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ width: "460px" }}>
        <DialogContentText id="alert-dialog-description" color="text.secondary">
          {textContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          fullWidth
          color="secondary"
          onClick={() => onCancel()}
        >
          {t("FORMS.CANCEL")}
        </Button>
        <Button
          disabled={loading}
          sx={{ gap: 1 }}
          fullWidth
          variant="contained"
          onClick={() => onSubmit()}
          autoFocus
        >
          {displayIcon && (
            <>
              {loading && (
                <CircularProgress size={16} sx={{ marginRight: "8px" }} />
              )}
              {!loading && <Icon name="trash" />}
            </>
          )}

          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
