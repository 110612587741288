import Icon from "@/components/base/Icon";
import DialogConfirmation from "@/components/base/dialog/dialog-confirmation";
import { Button } from "@dilypse/mui/base";
import { Box, Paper, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import useTopbarEditUser from "./useTopbarEditUser";

const TopbarEditUser = () => {
  const { t } = useTranslation();
  const { isDeleting, openModalDelete, setOpenModalDelete, handleDelete } =
    useTopbarEditUser();

  return (
    <Paper
      data-testid="topbar-container"
      sx={{
        padding: "8px",
        "@media (min-width: 1024px)": {
          padding: "8px 16px",
        },
      }}
    >
      <Stack
        flexDirection={{
          md: "row",
          sm: "column",
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={{
          sm: "8px",
          xs: "8px",
        }}
      >
        <Box></Box>
        <Button
          startIcon={<Icon name="delete-1" color="white" />}
          color="error"
          variant="contained"
          data-testid="button-action"
          sx={{
            width: "100%",
            "@media (min-width: 1024px)": {
              width: "fit-content",
            },
          }}
          onClick={() => setOpenModalDelete(true)}
        >
          {t("FORMS.DELETE")}
        </Button>
      </Stack>

      <DialogConfirmation
        title={t("USERS.DELETE_USER_TITLE")}
        textContent={t("USERS.DELETE_USER_CONFIRMATION")}
        buttonText={t("FORMS.CONFIRM")}
        loading={isDeleting}
        open={openModalDelete}
        onSubmit={handleDelete}
        onCancel={() => setOpenModalDelete(false)}
        t={t}

      />
    </Paper>
  );
};

export default TopbarEditUser;
