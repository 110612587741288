import FormController from "@/components/widget/forms/base/form-controller";
import PaperBlock from "@/components/widget/forms/base/paper-block";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const Domain = ({ control }) => {
    const { t } = useTranslation();
    return (
        <PaperBlock title={t("RESELLERS.DOMAIN_NAME")} paperElevation={1}>
            <Stack flexDirection={"row"} gap={1} className="fullWidthContent">
                <FormController
                    control={control}
                    fieldLabel={t("RESELLERS.DOMAIN_NAME")}
                    fieldName="quicksite_domain"
                    required={false}
                />
            </Stack>
        </PaperBlock>
    );
};

export default Domain;
