import { ResellerApi } from "@/api";
import { Column } from "@/components/widget/table/partials/custom-table";
import useNotification from "@/hooks/useNotification";
import { UserSessionService } from "@/services";
import UiStore from "@/store/ui";
import UserSessionStore from "@/store/user-session";
import { checkDisplayOfFullDate } from "@/utils";
import { Button } from "@dilypse/mui/base";
import { Link } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Link as LinkRouter } from "react-router-dom";

type ColumnProps = Column & {
  id: "name" | "created" | "action";
};

const useTableResellers = () => {
  const { t } = useTranslation();
  const { preferredLanguage } = UiStore();
  const [page, setPage] = useState(0);
  const rowsPerPage = 100;
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const { profile } = UserSessionStore();
  const [promoting, setPromoting] = useState<boolean>(false);
  const { notif } = useNotification();
  const navigate = useNavigate();

  const fetchResellers = useCallback(() => {
    return ResellerApi.fetchResellers({
      page_number: page,
      page_size: rowsPerPage,
      user_uid: profile?.uid,
    });
  }, [profile, page, rowsPerPage]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const promoteUser = useCallback(
    async ({ reseller_uid }) => {
      setPromoting(true);
      const promote = await UserSessionService.promoteUser({
        user_uid: profile?.uid,
        reseller_uid,
      });
      if (promote) {
        navigate("/accounts");
      } else {
        notif({
          show: true,
          type: "ERROR",
          message: t("SYSTEM_ERROR.INVALID_REQUEST"),
        });
      }
      setPromoting(false);
    },
    [profile]
  );

  const columns: readonly ColumnProps[] = useMemo(() => {
    return [
      {
        id: "name",
        label: t("COMMON.RESELLER"),
        component: ({ column, row }) => {
          return (
            <Link component={LinkRouter} to={`/resellers/edit/${row.uid}`}>
              {row[column.id]}
            </Link>
          );
        },
      },
      {
        id: "created",
        label: t("COMMON.CREATED_DATE"),
        width: 178,
        component: ({ column, row }) => {
          return (
            row[column.id] &&
            checkDisplayOfFullDate(row[column.id], preferredLanguage)
          );
        },
      },
      {
        id: "action",
        label: "",
        width: 135,
        component: ({ row }) => {
          return (
            <Button
              disabled={promoting}
              onClick={() =>
                promoteUser({
                  reseller_uid: row?.uid,
                })
              }
            >
              {t("COMMON.CONNEXION")}
            </Button>
          );
        },
      },
    ];
  }, [t, preferredLanguage]);

  useEffect(() => {
    (async () => {
      const response = await fetchResellers();
      const populated =
        response?.data?.map((data) => ({
          ...data,
          created:
            data.created &&
            dayjs(data.created, "YYYY-MM-DD").format("DD MMMM YYYY"),
        })) || [];
      setTotalCount(response?.metadata?.total_count || populated?.length);
      setRows(populated);
    })();

    return () => {
      setTotalCount(0);
      setRows([]);
      setPage(0);
    };
  }, []);

  return {
    columns,
    page,
    rowsPerPage,
    rows,
    totalCount,
    handleChangePage,
  };
};

export default useTableResellers;
