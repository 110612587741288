import { Input } from "@dilypse/mui/base";
import { Control, Controller } from "react-hook-form";

type ControllerFormInputProps<T> = {
  fieldName: string;
  label: string;
  control: Control<T>;
};

const ControllerFormInput = ({
  fieldName,
  label,
  control,
}: ControllerFormInputProps<any>) => {
  return (
    <Controller
      render={({ field, fieldState }) => (
        <Input
          label={label}
          required
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
          {...field}
        />
      )}
      control={control}
      name={fieldName}
    />
  );
};

export default ControllerFormInput;
