import { Tooltip, TooltipProps } from "@mui/material"

const CustomTooltip = ({ title, children, ...rest }: TooltipProps) => {
    return (
        <Tooltip enterDelay={1000} {...rest} title={title}>
            <span>
                {children}
            </span>
        </Tooltip>
    )
}

export default CustomTooltip
