import { Button } from "@dilypse/mui/base";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useStyles from "../style";

const Error404Component = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack
      className={classes.errorContainer}
      flexDirection={"column"}
      justifyContent={"center"}
    >
      <Stack flexDirection={"column"} className="notfound" gap={3}>
        <Box>
          <Typography className="error">404</Typography>
          <Typography variant="h1" className="error-message">
            {t("PAGE_ERRORS.NOT_FOUND.OOPS")}
          </Typography>
        </Box>

        <Typography variant="h2" className="error-desc">
          {t("PAGE_ERRORS.NOT_FOUND.MESSAGE")}
        </Typography>
        <Button
          className="action-btn"
          color="primary"
          variant="contained"
          onClick={() => navigate("/accounts")}
        >
          {t("PAGE_ERRORS.NOT_FOUND.BACK_TO_REPUTATION")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default Error404Component;
