import HeaderTitle from "@/components/widget/header-title";
import SendingContacts from "@/components/widget/sending-contacts";
import TableComptes from "@/components/features/company/table/table-comptes";
import TableUploadComptes from "@/components/features/company/table/table-upload-comptes";
import TopbarComptes from "@/components/features/company/topbar/topbar-accounts";

import useAccountsStore from "@/store/accounts";
import UiStore from "@/store/ui";
import { Paper, Stack } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const MemoizedTable = memo(TableComptes);

const ComptesPage = () => {
  const { t } = useTranslation();
  const { updateState } = UiStore();
  const { newContacts, sendContacts } = useAccountsStore();
  const [hasContacts, setHasContacts] = useState(false);

  useEffect(() => {
    updateState({ title: t("MENU.ACCOUNTS") });
  }, []);

  useEffect(() => {
    if (newContacts?.length > 0) {
      setHasContacts(true);
    } else {
      setHasContacts(false);
    }
  }, [newContacts]);

  return (
    <Stack
      gap={"24px"}
      sx={{
        height: "100%",
      }}
    >
      <HeaderTitle
        icon="profile"
        title={
          (!sendContacts &&
            newContacts?.filter((item) => !item.isValid || item.hasDuplicate)
              .length !== 0 &&
            t("COMPANIES.MODIFY")) ||
          (!sendContacts &&
            newContacts?.length !== 0 &&
            newContacts?.filter((item) => !item.isValid && item.hasDuplicate)
              ?.length === 0 &&
            t("COMPANIES.MODIFY")) ||
          (!sendContacts && newContacts?.length === 0 && t("MENU.ACCOUNTS")) ||
          (sendContacts && t("COMPANIES.NEW_COMPANY"))
        }
      />
      {!sendContacts && (
        <>
          {hasContacts && <TableUploadComptes />}
          {!hasContacts && (
            <>
              <TopbarComptes />
              <Paper data-testid="table-comptes">
                <MemoizedTable />
              </Paper>
            </>
          )}
        </>
      )}
      {sendContacts && <SendingContacts />}
    </Stack>
  );
};

export default ComptesPage;
