import DialogConfirmation from "@/components/base/dialog/dialog-confirmation";
import FormAction from "@/components/widget/forms/form-action";
import useAccountsStore from "@/store/accounts";
import { hasInvalidValue } from "@/utils";
import { Paper, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CustomTable from "@/components/widget/table/partials/custom-table";
import useTableUploadComptes from "./useTableUploadComptes";

const TableUploadComptes = () => {
  const {
    columns,
    page,
    rowsPerPage,
    totalCount,
    filteredData,
    handleChangePage,
    handleSubmit,
  } = useTableUploadComptes();
  const { t } = useTranslation();
  const { newContacts, update } = useAccountsStore();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Stack
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{
          height: "100%",
        }}
      >
        <Paper
          data-testid="table-comptes"
          sx={{
            "& td.MuiTableCell-root": {
              borderBottom: "none",
              "&:has(.isColumnInvalid)": {
                border: "1px solid #F46259",
              },
            },
            ".isColumnInvalid": {
              color: "error.main",
            },
            "& td:has(.sticky-action-button)": {
              position: "sticky",
              right: 0,
              backgroundColor: "#fff",
            },

            "& .MuiTableRow-root": {
              borderBottom: "1px solid #E4EAFA",
            },
            "& .MuiTableCell-root": {
              padding: "6px 16px",
            },
            "& .MuiTableCell-head": {
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 600,
              padding: "0px 16px",
            },
          }}
        >
          <CustomTable
            columns={columns}
            rows={filteredData}
            pagination={{
              page,
              rowsPerPage,
              totalCount,
            }}
            onPageChange={handleChangePage}
          />
        </Paper>
        <FormAction
          handleCancel={() => {
            setOpenDialog(true);
          }}
          isDisabled={hasInvalidValue(newContacts)}
          isLoading={false}
          isUpdating
          handleSubmit={handleSubmit}
          buttonText={t("BUTTON.RETRY")}
        />
      </Stack>

      <DialogConfirmation
        title={t("MODAL.CANCEL_SAVE")}
        textContent={t("MODAL.ARE_YOU_SURE")}
        loading={false}
        buttonText={t("MODAL.CONTINUE")}
        open={openDialog}
        displayIcon={false}
        onCancel={() => {
          setOpenDialog(false);
        }}
        onSubmit={() => {
          update({
            newContacts: [],
            newContactsValidationArray: [],
          });
        }}
        t={t}
      />
    </>
  );
};

export default TableUploadComptes;
