import Icon from "@/components/base/Icon";
import StyledAutocomplete from "@/components/base/styled-autocomplete";
import MAX_INPUT from "@/data/limiter";
import useGoogleAddress, { PlaceType } from "@/hooks/useGoogleAddress";
import { Input } from "@dilypse/mui/base/input";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

type GoogleApiResponse = {
  description: string;
  matched_substrings: Matchedsubstring[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormatting;
  terms: Term[];
  types: string[];
};

interface Term {
  offset: number;
  value: string;
}

type StructuredFormatting = {
  main_text: string;
  main_text_matched_substrings: Matchedsubstring[];
  secondary_text: string;
};

type Matchedsubstring = {
  length: number;
  offset: number;
};

type GoogleAddressAutocompleteOptionalProps = {
  helperText: string;
  disabled: boolean;
  required: boolean;
  error: boolean;
  value: string;
};

type GoogleAddressAutocompleteProps = {
  countryRestrictions: string[];
  label: string;
  onSelect: (data: any) => void;
  inputValueChange: (inputValue: string) => void;
} & Partial<GoogleAddressAutocompleteOptionalProps>;

const GoogleAddressAutocomplete = ({
  countryRestrictions = ["CA"],
  value = "",
  label = "",
  required = false,
  error = false,
  onSelect,
  inputValueChange,
  helperText = "",
  disabled = false,
}: GoogleAddressAutocompleteProps) => {
  const { RequestGoogle, FetchPlaceDetail } = useGoogleAddress({
    countryRestrictions,
  });
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const [options, setOptions] = useState<readonly PlaceType[] | any[]>([]);
  const [hasSelectedValue, setHasSelectedValue] = useState(false);
  const [hasDefaultValue, setHasDefaultValue] = useState(false);

  const initState = useCallback(() => {
    setHasSelectedValue(true);
    setOptions([]);
    setOpen(false);
  }, []);

  const handleSelect = useCallback(
    (placeId: string) => {
      initState();
      if (placeId && !hasSelectedValue) {
        FetchPlaceDetail(placeId, (data) => {
          onSelect(data);
          setInputValue(data.address);
        });
      }
    },
    [initState]
  );

  useEffect(() => {
    if (inputValue === "" || inputValue?.length < 3) {
      setOptions([]);
    }
    RequestGoogle(inputValue, (rows) => {
      if (!open && rows?.length > 0 && !hasSelectedValue) {
        setOptions(rows);
        if (rows?.length > 0) {
          setOpen(true);
        }
      }
    });
  }, [open, hasSelectedValue, inputValue, RequestGoogle]);

  useEffect(() => {
    setInputValue(value);
    if (!hasDefaultValue) {
      setHasSelectedValue(true);
      setHasDefaultValue(true);
    }
  }, [value, hasDefaultValue]);

  return (
    <StyledAutocomplete
      freeSolo
      open={open && options?.length > 0}
      inputValue={inputValue || ""}
      options={options}
      disableClearable
      renderInput={(params) => (
        <Input
          error={error}
          required={required}
          label={label}
          helperText={helperText}
          disabled={disabled}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Icon name="search" />,
          }}
        />
      )}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length <= MAX_INPUT.TEXT) {
          setInputValue(newInputValue);
          setOpen(false);
          setHasSelectedValue(false);
          if (typeof inputValueChange === "function") {
            inputValueChange(newInputValue);
          }
        }
      }}
      getOptionLabel={(option: any) =>
        (typeof option === "string" ? option : option.description) ?? ""
      }
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_: any, newValue: GoogleApiResponse) => {
        handleSelect(newValue?.place_id);
      }}
      onBlur={() => initState()}
      renderOption={(props, option, state, ownerState) => (
        <Box component="li" sx={{ gap: 1 }} {...props}>
          <LocationOnIcon sx={{ color: "text.secondary" }} />
          {ownerState.getOptionLabel(option)}
        </Box>
      )}
    />
  );
};

export default GoogleAddressAutocomplete;
