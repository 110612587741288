import { Button } from "@dilypse/mui/base";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon";
import CustomTooltip from "../../Tooltip";
import useStyles from "./styles";

interface TitleWrapperProps {
  handlePreviousButton: () => void;
  title: string;
  tooltipText?: string;
}

const TitleWrapper = ({
  handlePreviousButton,
  title,
  tooltipText,
}: TitleWrapperProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.titleWrapper}>
      <CustomTooltip title={tooltipText || t("COMMON.BACK_TOOLTIP")}>
        <Button onClick={handlePreviousButton}>
          <Icon name="back" />
        </Button>
      </CustomTooltip>
      <Typography variant="h1">{t(title)}</Typography>
    </Box>
  );
};

export default TitleWrapper;
